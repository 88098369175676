<template>
  <div>
    <p class="font-weight-semi-bold">
      {{ $t('onboarding.classification.investorClassification.intro') }}
    </p>
    <v-form>
      <v-radio-group
        v-if="professionalSecuritiesOptions.length > 1"
        v-model="allProfessional"
        :rules="rules.boolean"
      >
        <div class="d-flex">
          <v-radio
            :label="$t('onboarding.classification.investorClassification.radio.all')"
            :value="true"
            class="mt-2"
            id="all-securities"
          ></v-radio>
          <v-btn
            icon
            @click="openModal"
            class="ml-3"
          >
            <v-icon>
              mdi-information-outline
            </v-icon>
          </v-btn>
        </div>
        <div
          class="ml-5"
          v-if="typeof allProfessional === 'boolean' && allProfessional"
        >
          <v-checkbox
            v-for="(option, index) in allSecurities"
            :key="index"
            :label="$t(`onboarding.investmentSurvey.products.${option}`)"
            color="white"
            :input-value="true"
            :disabled="true"
          ></v-checkbox>
        </div>
        <v-radio
          :label="$t('onboarding.classification.investorClassification.radio.choose')"
          :value="false"
          class="mt-2"
          id="choose-securities"
        ></v-radio>
        <div
          class="ml-5"
          v-if="typeof allProfessional === 'boolean' && !allProfessional"
        >
          <v-checkbox
            v-for="(option, index) in professionalSecuritiesOptions"
            :key="index"
            :label="$t(`onboarding.investmentSurvey.products.${option}`)"
            class="professional-securities-option"
            color="white"
            @click="togglePro(option)"
          ></v-checkbox>
        </div>
      </v-radio-group>
      <div v-else>
        <v-checkbox
          v-for="(option, index) in professionalSecuritiesOptions"
          :key="index"
          :label="$t(`onboarding.investmentSurvey.products.${option}`)"
          color="white"
          @click="togglePro(option)"
        ></v-checkbox>
      </div>
    </v-form>
    <div v-if="!settings">
      <a @click="openSkipModal">
        <u>{{ $t('onboarding.classification.investorClassification.skip.title') }}</u>
      </a>
    </div>
    <Modal
      :dialog="isSkipModalOpen"
    >
      <template v-slot:content>
        <div class="mt-10 d-flex flex-column">
          <p class="defaultText--text">
            {{ $t('onboarding.classification.investorClassification.skip.modal.question') }}
          </p>
          <div class="d-flex justify-center">
            <v-btn
              color="primary"
              class="primary--text mt-5 mr-2"
              outlined
              @click="closeSkipModal"
            >
              {{ $t('onboarding.classification.investorClassification.skip.modal.answer.no') }}
            </v-btn>
            <v-btn
              color="primary"
              class="black--text mt-5 ml-2"
              @click="submit(true)"
            >
              {{ $t('onboarding.classification.investorClassification.skip.modal.answer.yes') }}
            </v-btn>
          </div>
        </div>
      </template>
    </Modal>
    <Modal
      :dialog="isModalOpen"
      @close="closeModal"
    >
      <template v-slot:content>
        <div class="mt-10 d-flex flex-column">
          <p class="defaultText--text">
            {{ $t('onboarding.classification.investorClassification.modal.text') }}
          </p>
          <v-btn
            color="primary"
            class="align-self-center black--text mt-5"
            @click="closeModal"
          >
            {{ $t('onboarding.classification.investorClassification.modal.button') }}
          </v-btn>
        </div>
      </template>
    </Modal>
  </div>
</template>

<script>
import { mapActions } from 'vuex';
import clone from '@/utils/clone';
import { boolean } from '@/utils/validators';
import Modal from '@/components/Shared/Modal.vue';

export default {
  name: 'InvestorClassification',
  components: {
    Modal,
  },
  props: {
    settings: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      data: clone(this.$store.state.user.data),
      rules: {
        boolean,
      },
      allProfessional: null,
      isModalOpen: false,
      isSkipModalOpen: false,
      professionalSecurities: [],
      allSecurities: ['InvestmentFund', 'GovernmentBonds', 'Bond', 'Share', 'CryptoCurrency', 'UnsecuredJuniorDebt'],
    };
  },
  computed: {
    professionalSecuritiesOptions() {
      const options = [];
      Object.values(this.data.investmentSurvey.investmentExperience).forEach((type) => {
        if (type[0].experience === 'GreaterThan5Years' && type[0].knowledge) {
          options.push(type[0].type);
        }
        if (type[1].experience === 'GreaterThan5Years' && type[1].knowledge) {
          options.push(type[1].type);
        }
      });
      return options;
    },
  },
  watch: {
    allProfessional(newVal) {
      if (!newVal) {
        this.professionalSecurities = [];
        this.setFormValidity(false);
      } else {
        this.setFormValidity(true);
      }
    },
    professionalSecurities(newVal) {
      if (newVal.length > 0) {
        this.setFormValidity(true);
      } else {
        this.setFormValidity(false);
      }
    },
  },
  created() {
    this.setFormValidity(false);
  },
  methods: {
    ...mapActions('onboarding', [
      'setFormValidity',
    ]),
    ...mapActions('user', [
      'updateUser',
      'updateOnboarding',
      'setMainStage',
      'setSubStage',
    ]),
    ...mapActions('settings', [
      'nextInvestorClassificationStep',
      'setClassificationInvestorClassification',
    ]),
    openModal() {
      this.isModalOpen = true;
    },
    closeModal() {
      this.isModalOpen = false;
    },
    openSkipModal() {
      this.isSkipModalOpen = true;
    },
    closeSkipModal() {
      this.isSkipModalOpen = false;
    },
    togglePro(option) {
      const index = this.professionalSecurities.indexOf(option);
      if (index > -1) {
        this.professionalSecurities.splice(index, 1);
      } else {
        this.professionalSecurities.push(option);
      }
    },
    async submit(abort) {
      const investorClassification = {};
      if (this.allProfessional) {
        investorClassification.isProfessionalForAllSecurities = true;
      } else {
        investorClassification.isProfessionalForAllSecurities = false;
        investorClassification.professionalSecurities = this.professionalSecurities;
      }
      investorClassification.abortProcess = abort;
      const input = {
        investor: {
          classification: {
            investorClassification,
          },
        },
        mainStage: 'classification',
        subStage: 'investorClassification',
      };
      try {
        await this.updateOnboarding({
          input,
          newData: this.data,
        });
        this.setSubStage('overview');
      } catch (error) {
        this.$notify.error(error.message);
      }
    },
    edit() {
      this.setClassificationInvestorClassification({
        investorClassification: this.allProfessional ? 'Professional' : 'Partial',
        isProfessionalForAllSecurities: this.allProfessional,
        professionalSecurities: this.allProfessional ? [] : this.professionalSecurities,
      });
      this.nextInvestorClassificationStep();
    },
  },
};
</script>

<style scoped>
.font-weight-semi-bold {
  font-weight: 600;
}
.v-input--selection-controls:first-child {
  margin-top: 5px;
}
.v-input--selection-controls:nth-child(n+2) {
  margin-top: 0px;
}
.v-input--checkbox {
  height: 35px;
}
</style>
